<template>
  <div class="cardCountry">
    <div class="cardCountry-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardCountry-title">{{ title }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>

      <div
        class="cardCountry-subtitle btn-plus"
        @click="collapseBottom = !collapseBottom"
      >
        {{ $t('country_details') }}
        <CIcon name="cil-plus" />
      </div>

      <CCollapse :show="showCollapseBottom == collapseBottom">
        <BlockInfo
          :title="$t('code') + ':'"
          v-model="_country.code"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('language') + ':'"
          v-model="_country.language"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('truck_limit') + ':' + ' ' + ($t('hours'))"
          type="number"
          v-model="_country.truckHoursLimit"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('delivery_disappearance') + ':' + ($t('hours'))"
          type="number"
          v-model="_country.deliveryDisappearanceMinutes"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('security_code') + ':'"
          v-model="_country.securityCodeType"
          :selected="_country.securityCodeType.toString()"
          :values="securityCodeTypeValues"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('jobsite_sampling') + ':'"
          v-model="_country.jobsiteSampling"
          :selected="_country.jobsiteSampling"
          :values="jobsiteSamplingValues"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('plant_sampling') + ':'"
          v-model="_country.plantSampling"
          :selected="_country.plantSampling"
          :values="plantSamplingValues"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('truck_washing') + ':'"
          v-model="_country.truckWashing"
          :selected="_country.truckWashing"
          :values="truckWashingValues"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('delivery_rejected') + ':'"
          v-model="_country.deliveryRejectedSignature"
          :selected="_country.deliveryRejectedSignature"
          :values="deliveryRejectedSignatureValues"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('customer_satisfaction') + ':'"
          v-model="_country.customerSatisfaction"
          :selected="_country.customerSatisfaction"
          :values="customerSatisfactionValues"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('gps_interval') + ':' + '(' + $t('seconds') + ')'"
          type="number"
          v-model="_country.upgradeGPSInterval"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('send_geolocation_text')"
          type="number"
          v-model="_country.sendGeolocationInterval"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('my_order_signature') + ':'"
          v-model="_country.externalDeliverySignature"
          :selected="_country.externalDeliverySignature"
          :values="externalDeliverySignatureValues"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('zonda_enviroment')"
          type="number"
          v-model="_country.zondaEnvironment"
          :disabled="true"
        />
        <!-- addSafetyCheckList -->
        <BlockColumn
          :title="$t('safety_check') + ':'"
          :eventName="!inputsDisabled ? 'add-safety-check' : null"
          @add-safety-check="addSafetyCheckList"
        >
          <BlockItem
            v-for="(safetyCheck) in safetyCheckList"
            :key="safetyCheck.key"
            :size="'1-2'"
            :border="true"
          >
            <CButton
              v-if="!inputsDisabled"
              size="sm"
              class="buttonDelete"
              @click="removeSafetyCheckList(safetyCheck.key)"
            >
              <CIcon name="cil-trash" />
            </CButton>
            <BlockInfo
              v-for="(item) in safetyCheck.value"
              :title="item.key"
              :key="item.key"
              :disabled="inputsDisabled"
              v-model="item.value"
            />
          </BlockItem>
        </BlockColumn>

        <!-- noSignatureReasonsList -->
        <BlockColumn
          :title="$t('no_signature_reason') + ':'"
          :eventName="!inputsDisabled ? 'add-signature-reasons' : null"
          @add-signature-reasons="addSignatureReasonsList"
        >
          <BlockItem
            v-for="(item) in noSignatureReasonsList"
            :key="item.key"
            :size="'1-3'"
          >
            <BlockInfo
              :disabled="inputsDisabled"
              v-model="item.value"
              :buttonDelete="!inputsDisabled"
              :_key="item.key"
              @block-info-remove="removeSignatureReasonsList"
            />
          </BlockItem>
        </BlockColumn>

        <div class="cardCountry-sectionButtons">
          <CButton
            color="white"
            size="lg"
            class="cardCountry-button"
            @click="sendEvent()"
            >{{$t('cancel')}}</CButton
          >
          <CButton
            :color="btnEventType"
            size="lg"
            class="cardCountry-button"
            @click="actionEvent()"
            >{{ btnEventText }}</CButton
          >
        </div>
      </CCollapse>
    </div>
    <div class="cardCountryShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";

export default {
  name: "CardCountry",
  components: {
    BlockInfo,
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    country: Object,
    listCountryCodes: Array,
    title: String,
    notification: String,
    notificationType: String,
    btnEventText: String,
    btnEventType: String,
    inputsDisabled: Boolean,

    showCollapseBottom: Boolean,
  },
  computed: {
    _country() {
      return this.country;
    },

    securityCodeTypeValues() {
      return [
        { _key: "0", value: "None" },
        { _key: "1", value: "Order" },
        { _key: "2", value: "Delivery" },
      ];
    },
    jobsiteSamplingValues() {
      return ["disabled", "optional", "mandatory"];
    },
    plantSamplingValues() {
      return ["disabled", "optional", "mandatory"];
    },
    truckWashingValues() {
      return ["disabled", "optional", "mandatory"];
    },
    customerSatisfactionValues() {
      return ["disabled", "optional", "mandatory"];
    },
    externalDeliverySignatureValues(){
      return ["disabled", "enabled"];
    },
    deliveryRejectedSignatureValues() {
      return ["disabled", "enabled"];
    },
  },
  data() {
    return {
      noSignatureReasonsList: [],
      safetyCheckList: [],
      collapseBottom: true,
    };
  },
  created() {
    const self = this;

    self._country.noSignatureReasonsList.map((e, i) => {
      self.noSignatureReasonsList.push({
        key: "noSignature" + i,
        value: e,
      });
    });

    // TODO: añadimos los listCountryCodes si hubiera nuevos codigos
    let codes = self.listCountryCodes;

    for (const i in self._country.safetyCheckList) {
      const element = self._country.safetyCheckList[i];
      let list = [];

      for (const code in element) {
        codes = codes.filter((e) => e != code);
        let _newState = {
          key: code,
          value: element[code],
        };

        list.push(_newState);
      }

      if (codes && codes.length > 0) {
        codes.map((e) => {
          let _newState = {
            key: e,
            value: "",
          };

          list.push(_newState);
        });
      }

      self.safetyCheckList.push({
        key: "safetyCheck" + i,
        value: list,
      });
    }
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card-country", false);
    },

    addSignatureReasonsList() {
      const self = this;
      self.noSignatureReasonsList.push({
        key: "noSignature" + new Date().getTime(),
        value: "",
      });
    },
    removeSignatureReasonsList(_key) {
      const self = this;
      self.noSignatureReasonsList = self.noSignatureReasonsList.filter(
        (e) => e.key != _key
      );
    },

    addSafetyCheckList() {
      const self = this;
      // TODO: aqui deberemos obtener los paises desde la ddbb para añadirlos
      self.safetyCheckList.push({
        key: "safetyCheck" + new Date().getTime(),
        value: self.listCountryCodes.map((e) => {
          return {
            key: e,
            value: "",
          };
        }),
      });
    },
    removeSafetyCheckList(_key) {
      const self = this;
      self.safetyCheckList = self.safetyCheckList.filter((e) => e.key != _key);
    },

    actionEvent() {
      const self = this;
      self._country;
      self.safetyCheckList;
      self.noSignatureReasonsList;

      // desfragmentacion de las safetyCheckList
      let safetyCheckList = [];
      self.safetyCheckList.map((item) => {
        let _item = {};
        item.value.map((e) => {
          _item[e.key] = e.value;
        });
        safetyCheckList.push(_item);
      });
      self._country.safetyCheckList = safetyCheckList;

      // desfragmentacion de las nosignatureList
      let noSignatureReasonsList = [];
      self.noSignatureReasonsList.map((e) => {
        if (e.value != "") {
          noSignatureReasonsList.push(e.value);
        }
      });
      self._country.noSignatureReasonsList = noSignatureReasonsList;

      if (self.btnEventType == "success") {
        self.$emit("update-country", self._country);
      } else {
        self.$emit("delete-country", self._country.code);
      }
    },
  },
};
</script>

<style scoped>
.cardCountryShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardCountry {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardCountry-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 800px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardCountry-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardCountry-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardCountry-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardCountry-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardCountry-button {
  margin-left: 1em;
  font-size: 14px;
}

.buttonDelete {
  position: absolute;
  top: 0;
  right: 0;
}
</style>